import { Stack, Typography, useTheme } from '@mui/material';

import React from 'react';
import BookTypes from '~/types/app/book';

import HighlightedText from '~/components/molecules/HighlightedText';

interface BookTilePricingProps {
    book: BookTypes;
    condensed?: boolean;
}

const BookTilePricing: React.FC<BookTilePricingProps> = ({ book }: BookTilePricingProps) => {
    const theme = useTheme();

    return (
        <Stack sx={{ mt: 2, flexDirection: 'column', flex: 1 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                {book.meta.lowestAvailablePrice > 0 && (
                    <Typography component="span" variant="p3" sx={{ mr: 2 }}>
                        {book.meta.hasMultiplePrices ? 'Fra kr' : 'Kr'}
                    </Typography>
                )}
                <Typography component="span" variant="p3" sx={{ fontWeight: 700 }}>
                    <HighlightedText
                        color={theme.palette.primary.light}
                        tilt="-0.1"
                        variant="p3"
                        text={book.meta.lowestAvailablePrice > 0 ? `${book.meta.lowestAvailablePrice},-` : 'Gratis'}
                        textSx={{ fontWeight: 700, color: theme.palette.primary.contrastText }}
                    />
                </Typography>
            </Stack>
        </Stack>
    );
};

export default BookTilePricing;
