import React from 'react';
import { Typography } from '@mui/material';

import Dot from '~/components/atoms/Dot';
import BookTypes from '~/types/app/book';

type BookTileInfoProps = { book: BookTypes };

const BookTileInfo: React.FC<BookTileInfoProps> = ({ book }: BookTileInfoProps) => {
    return (
        <Typography
            variant="p3"
            sx={{
                mt: 2,
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {book.publishedYear}
            {book.meta.pageNum > 0 && (
                <>
                    <Dot
                        sx={{
                            mb: '2px',
                            mx: 2,
                        }}
                    />
                    {book.meta.pageNum} sider
                </>
            )}
        </Typography>
    );
};
export default BookTileInfo;
