import { Box, Theme, SxProps } from '@mui/material';
import { mergeSx } from '~/utils/mui';

interface HighlightedTextProps {
    text: string;
    color: string;
    variant: string;
    tilt: string;
    textSx?: SxProps<Theme>;
}

const HighlightedText = ({ text, variant, color, tilt, textSx }: HighlightedTextProps) => {
    if (text.length < 1) {
        return null;
    }

    const sizing: { [key: string]: Record<string, string> } = {
        h0: {
            top: '9px',
            right: '-5px',
            bottom: '-3px',
            left: '-5px',
            borderRadius: '4px',
        },
        p3: {
            top: '-1px',
            right: '-1px',
            bottom: '0px',
            left: '-4px',
            borderRadius: '2px',
        },
    };

    return (
        <Box
            component="span"
            sx={{
                position: 'relative',
            }}
        >
            <Box component="span" sx={mergeSx({ position: 'relative', zIndex: 10 }, textSx)}>
                {text}
            </Box>
            <Box
                component="span"
                sx={{
                    position: 'absolute',
                    transform: tilt ? `rotate(${tilt}deg)` : 'none',
                    backgroundColor: color,
                    zIndex: 0,
                    ...(sizing[variant] || {}),
                }}
            />
        </Box>
    );
};

export default HighlightedText;
