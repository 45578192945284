import { Box, ButtonBase, Chip, Stack, Typography, useTheme } from '@mui/material';

import { FC, MouseEventHandler, useRef, useState } from 'react';

import Heading from '~/components/atoms/Heading';
import { Article as ArticleIcon, Marker as MarkerIcon } from '~/components/atoms/Icon';
import Logo from '~/components/atoms/Logo';

import { getBookImageUrl, stringifyReadingTime } from '~/utils/book';
import { mergeSx } from '~/utils/mui';

import { useCurrentUser } from '~/utils/user';
import BookTileInfo from './components/BookTileInfo';
import BookTilePricing from './components/BookTilePricing';
import BookTileProps from './types';

const BookTile: FC<BookTileProps> = ({
    book,
    behaviourData,
    condensed,
    hideHighlights,
    hideAllvitPluss,
    hideBookInformation,
    hideProgress,
    showPricingInformation,
    access,
    sx,
    ...props
}) => {
    const theme = useTheme();
    const imageRef = useRef(null);
    const wrapperRef = useRef<HTMLElement>(null);
    const [deg, setDeg] = useState<{ x: number; y: number } | null>(null);

    const currentUser = useCurrentUser();

    const handleMouseMove: MouseEventHandler<HTMLImageElement> = (e) => {
        if (wrapperRef && wrapperRef.current) {
            const { top, left, width, height } = wrapperRef.current.getBoundingClientRect();
            const halfW = width / 2;
            const halfH = height / 2;

            const coorX = halfW - (e.clientX - left);
            const coorY = halfH - (e.clientY - top);
            setDeg({ x: (coorX / halfW) * -4, y: (coorY / halfH) * 4 });
        }
    };

    const handleMouseLeave: MouseEventHandler<HTMLImageElement> = () => {
        setDeg(null);
    };

    return (
        <ButtonBase
            sx={mergeSx(
                {
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: theme.radius.all,
                    '&:hover .bookTileBackground': {
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? theme.palette.background.dark
                                : theme.palette.background.lighter,
                    },
                    '&:hover .coverWrapper': {
                        boxShadow: theme.shadows[3],
                        transform: 'scale(1.04)',
                    },
                    '&.Mui-focusVisible': {
                        boxShadow: theme.shadows[24],
                    },
                },
                sx,
            )}
            {...props}
        >
            <Stack
                component="span"
                sx={{
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    zIndex: '20',
                    height: '50px',
                    width: '100%',
                    paddingTop: '136%',
                    overflow: 'visible',
                    marginBottom: '-124px',
                }}
            >
                <Box
                    component="span"
                    sx={{
                        px: 4,
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                    }}
                    ref={wrapperRef}
                >
                    <Box
                        className="coverWrapper"
                        sx={{
                            position: 'relative',
                            width: '100%',
                            borderRadius: theme.radius.all,
                            overflow: 'hidden',
                            transition:
                                'transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
                            transform: 'perspective(600px) translate3d(0, 0, 0)',
                        }}
                        style={
                            deg
                                ? {
                                      transform: `perspective(600px) translate3d(0, -2px, 0) scale(1.04) rotateY(${deg.x}deg) rotateX(${deg.y}deg)`,
                                  }
                                : {}
                        }
                        onMouseLeave={handleMouseLeave}
                        onMouseMove={handleMouseMove}
                    >
                        <Box
                            ref={imageRef}
                            component="img"
                            loading="lazy"
                            onError={({ currentTarget }) => {
                                currentTarget.src = getBookImageUrl(book, true);
                            }}
                            sx={{
                                display: 'block',
                                width: '100%',
                            }}
                            src={book.imageUrl}
                            alt=""
                        />
                    </Box>
                </Box>
            </Stack>

            <Stack
                component="span"
                className="bookTileBackground"
                sx={{
                    pt: '140px',
                    px: 5,
                    pb: 4,
                    position: 'relative',
                    transition: 'background-color 0.3s ease-out',
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.background.dark : theme.palette.background.light,
                    borderRadius: theme.radius.all,
                    display: 'flex',
                    flexDirection: 'column',
                    width: 1,
                    alignItems: 'flex-start',
                    zIndex: '10',
                }}
            >
                <Heading
                    component="span"
                    variant="p2"
                    sx={{
                        display: 'block',
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 700,
                    }}
                >
                    {book.title}
                </Heading>

                {!hideHighlights && behaviourData && (
                    <Stack direction="row" sx={{ mb: 4 }}>
                        {behaviourData.highlights && (
                            <Stack direction="row">
                                <ArticleIcon sx={{ mr: 3, fontSize: 14, color: theme.palette.grey[600] }} />
                                <Typography variant="p4" color="text.muted" sx={{ mr: 6 }}>
                                    {Object.keys(behaviourData.highlights).length}
                                </Typography>
                            </Stack>
                        )}
                        {behaviourData.notes && (
                            <Stack direction="row">
                                <MarkerIcon sx={{ mr: 3, fontSize: 14, color: theme.palette.grey[600] }} />
                                <Typography variant="p4" color="text.muted">
                                    {Object.keys(behaviourData.notes).length}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                )}
                {!hideBookInformation && <BookTileInfo book={book} />}
                {showPricingInformation && !currentUser.user.organisation.isOrganisationUser && (
                    <BookTilePricing condensed={condensed} book={book} />
                )}
                {!hideProgress && behaviourData && (
                    <Stack sx={{ width: '100%' }}>
                        <Typography variant="p4" component="div" color="text.muted" sx={{ mb: 2 }}>
                            {stringifyReadingTime(book.meta.readingTime)}
                        </Typography>
                    </Stack>
                )}
                {!hideAllvitPluss && book.meta.availableInSubscription && (
                    <Stack direction="row" sx={{ position: 'absolute', right: '0', bottom: '0', pr: 5, pb: 5 }}>
                        <Logo
                            variant="plus"
                            sx={{
                                width: 22,
                                height: 'auto',
                                color:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.secondary.main
                                        : theme.palette.common.white,
                            }}
                        />
                    </Stack>
                )}
                {access && (
                    <Stack sx={{ mt: 2, flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                        <Typography variant="p3">
                            {access.label}{' '}
                            {access.origin === 'RENTED' && (
                                <>
                                    {!access.expired && access.expiresLabel && <span>tom: {access.expiresLabel}</span>}
                                    {access.expired && (
                                        <Chip label="Utløpt" size="small" color="error" sx={{ ml: 2 }} />
                                    )}
                                </>
                            )}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </ButtonBase>
    );
};

export default BookTile;
